import { useTheme } from '../../hooks';
import { makeStyles } from '../../material';

const thumbnailPlaceholderStyle = {
  alignItems: 'center',
  border: '1px solid transparent',
  borderRadius: '5px',
  display: 'flex',
  height: '70px',
  justifyContent: 'center',
  overflow: 'hidden',
  width: '80px',
};

const documentContentStyle = {
  color: '#555555',
  flexGrow: 1,
  fontSize: '0.75rem',
  lineHeight: 1.7,
  marginTop: 4,
  maxWidth: '550px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const outlinedDocumentContentStyle = {
  ...documentContentStyle,
  borderRadius: '5px',
  padding: 3,
};

const documentContainerBaseStyle = {
  color: '#222222',
  display: 'block',
  padding: '16px 24px',
  textDecoration: 'none',
};

export const useStyles = (props?: DocumentStylesProps) => {
  const theme = useTheme();

  return makeStyles(
    {
      actionButtonContainer: {
        marginLeft: '4px',
      },
      attachmentIcon: {
        color: '#bbb',
        fontSize: '1.25rem',
        marginRight: '8px',
      },
      connectorLogo: {
        maxHeight: '100%',
        padding: '2px',
        width: '18px',
      },
      container: {
        alignItems: 'center',
        display: 'flex',
      },
      content: documentContentStyle,
      date: {
        color: '#888',
        fontSize: '0.75rem',
        lineHeight: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      dateContainer: { overflow: 'hidden', width: '100%' },
      dateTextContainer: {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
      },
      displayFlex: {
        display: 'flex',
        overflow: 'hidden',
      },
      documentContainer: {
        ...documentContainerBaseStyle,
        '&:hover': {
          '& .addLabelButton': {
            opacity: '1 !important',
          },
          backgroundColor: theme.designSystem.colors.card.main,
          cursor: 'pointer',
        },
        padding: '16px 24px',
      },
      documentContainerAttachment: {
        ...documentContainerBaseStyle,
        border: '1px solid #eee',
        borderRadius: '8px',
        padding: '20px 32px 16px 16px',
      },
      documentContainerClicked: {
        ...documentContainerBaseStyle,
        backgroundColor: '#e8f0fe',
        padding: props?.bookmarkView ? '8px 24px' : '16px 24px',
      },
      documentContainerObfuscated: documentContainerBaseStyle,
      documentConversation: {
        color: '#333',
        fontSize: '0.875rem',
        fontWeight: 600,
      },
      documentDateContainer: {
        display: 'flex',
      },
      documentFooterContainer: {
        '& div ~ div': {
          paddingLeft: '20px',
        },
        alignItems: 'center',
        display: 'flex',
        marginBottom: '1 rem',
        marginTop: '1rem',
      },
      documentHeaderContainer: {
        alignItems: 'center',
        display: 'flex',
        position: 'relative',
      },
      downloadButton: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem',
        marginTop: '3rem',
      },
      downloadSubtitle: {
        color: '#888',
        marginTop: '0',
      },
      duplicateDocumentContainer: {
        ...documentContainerBaseStyle,
        '&:hover': {
          backgroundColor: theme.designSystem.colors.card.main,
          cursor: 'pointer',
        },
        borderBottom: 'unset',
        padding: '4px 24px',
        width: 'calc(100% - 48px)',
      },
      duplicateDocumentContainerClicked: {
        ...documentContainerBaseStyle,
        backgroundColor: '#e8f0fe',
        padding: '4px 24px',
      },
      duplicateFileContainer: {
        borderLeft: '1px solid #BFBFBF',
        marginLeft: '10px',
      },
      duplicateFilesContainer: {
        marginTop: '-4px',
        padding: '6px 0px 6px 24px',
      },
      emptyThumbnail: {
        alignItems: 'center',
        border: '1px solid #eee',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'center',
        minHeight: props?.isSimilarFile ? '45px' : '70px',
        minWidth: props?.isSimilarFile ? '50px' : '80px',
      },
      fileDataContainer: {
        flex: '1 1 0%',
        paddingLeft: props?.bookmarkView ? '0px' : '20px',
        width: 'calc(100% - 100px)',
      },
      fileDocumentContainer: {
        ...documentContainerBaseStyle,
        '&:hover': {
          '& .addLabelButton': {
            opacity: '1 !important',
          },
          backgroundColor: theme.designSystem.colors.card.main,
          cursor: 'pointer',
        },
        overflow: 'hidden',
        padding: props?.isSimilarFile
          ? '16px 16px'
          : props?.bookmarkView
          ? '8px 24px'
          : '16px 24px',
        width: '100%',
      },
      fileDocumentContainerClicked: {
        backgroundColor: '#e8f0fe',
      },
      fileInnerContainer: {
        alignItems: 'start',
        display: 'flex',
      },
      header: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: '100%',
        width: '100%',
      },
      headerContentContainer: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: '100%',
      },
      headerContentContainerWithLabels: {
        alignItems: 'center',
        display: 'flex',
        maxWidth: 'calc(100% - 180px)',
      },
      headerPreview: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      },
      mailMetasContainer: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
      },
      matchedOutlinedContent: {
        ...outlinedDocumentContentStyle,
        borderColor: '#888',
      },
      medianContainer: {
        color: '#888',
        marginRight: '4px',
      },
      messageAttachmentsContainer: {
        marginTop: '12px',
      },
      messageConnectorLogo: {
        alignItems: 'center',
        display: 'flex',
        marginLeft: '4px',
      },
      messageContainer: {
        marginLeft: '6px',
      },
      messageHeader: {
        alignItems: 'center',
        display: 'flex',
      },
      messageHeaderContent: {
        alignItems: 'center',
        display: 'flex',
        flex: '0 0 auto',
        paddingLeft: '12px',
      },
      messageHeaderDate: {
        marginLeft: '8px',
      },
      messageMetasContainer: {
        alignItems: 'center',
        color: '#888',
        display: 'flex',
        fontSize: '12px',
      },
      messageMetasItem: {
        marginLeft: '4px',
      },
      messageReceiverArrow: {
        color: '#888',
        height: 'aut',
        marginRight: '2px',
        minWidth: '10px',
      },
      outlinedContent: outlinedDocumentContentStyle,
      prestation: {
        border: '1px solid #ddd',
        borderRadius: '5px',
        padding: '5px 10px 5px 10px',
      },
      prestationContent: {
        color: '#666',
        fontSize: '0.75rem',
        marginTop: 5,
      },
      prestationsContainer: {
        marginTop: 5,
      },
      prestationTitle: {
        color: '#333',
        fontSize: '0.875rem',
        fontWeight: 600,
      },
      resultDuplicateElementMetas: {
        display: 'flex',
        overflow: 'none',
        width: '100%',
      },
      resultElementContainer: {
        display: 'flex',
        padding: '4px',
      },
      resultElementFileType: {
        height: 'auto',
        marginRight: '10px',
        width: '18px',
      },
      resultElementMaxWidth: {
        maxWidth: '30%',
      },
      resultElementMeta: {
        alignItems: 'center',
        color: '#888',
        display: 'flex',
        marginRight: '4px',
      },
      resultElementMetaIcon: {
        minHeight: '16px',
        minWidth: '16px',
      },
      resultElementMetas: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '4px',
        width: '100%',
      },
      resultElementMetaText: {
        fontSize: '0.75rem',
        fontWeight: 500,
        marginLeft: 3,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      seeMoreButton: {
        '&:hover': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
        alignItems: 'center',
        color: '#245EE5',
        display: 'flex',
        fontSize: '0.75rem',
        fontWeight: 600,
        marginLeft: '16px',
      },
      seeMoreButtonContainer: {
        padding: '4px 0 4px 0',
      },
      similarResultsTitle: {
        color: theme.designSystem.colors.aux.main,
        fontSize: '12px',
        fontWeight: 600,
        margin: '8px 0px 0px 16px',
      },
      singleMessageDateContainer: {
        marginLeft: '8px',
      },
      singleMessageHeader: {
        alignItems: 'center',
        display: 'flex',
        marginTop: '-4px',
      },
      thumbnailContainer: {
        ...thumbnailPlaceholderStyle,
        borderColor: '#eee',
        height: props?.isSimilarFile ? '45px' : '70px',
        width: props?.isSimilarFile ? '50px' : '80px',
      },
      thumbnailImg: {
        height: props?.isSimilarFile ? '45px' : '70px',
        objectFit: 'cover',
        objectPosition: 'top',
        width: '100%',
      },
      thumbnailLogo: {
        height: 'auto',
        marginLeft: '1px',
        marginTop: '1px',
        opacity: '0.15',
        width: props?.isSimilarFile ? '20px' : '32px',
      },
      thumbnailPlaceholder: {
        ...thumbnailPlaceholderStyle,
        height: 0,
      },
      thumbnailsContainer: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
        width: '100%',
      },
      title: {
        fontSize: '0.875rem',
        fontWeight: 600,
        marginLeft: '8px',
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
      treeStructure: {
        alignItems: 'center',
        display: 'flex',
        overflow: 'hidden',
      },
    },
    { index: 1 },
  )(props);
};

interface DocumentStylesProps {
  bookmarkView?: boolean;
  isPreviewOpened?: boolean;
  isSimilarFile?: boolean;
}
