/* eslint-disable no-nested-ternary */
import { ConnectorSyncState, LocalSources, Source } from '@outmind/types';
import React, { useState } from 'react';

import { useFeatureFlags, useTranslations } from '../../../hooks';
import {
  DeleteIcon,
  GroupAddIcon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MoreHorizIcon,
  RefreshIcon,
  SettingsIcon,
} from '../../../material';
import {
  ConfigureLocalConnectorDialog,
  ConfigureSourceDialog,
} from '../../SourcesConfigurationDialog';
import { SyncProgress } from '../../SyncProgress';
import { useStyles } from './styles';

export const ManageSourceMenu: React.FC<ManageSourceMenuProps> = ({
  connectorId,
  connectorName,
  deleteDisabled,
  refreshDisabled,
  onDelete,
  onShare,
  onRefresh,
  isRefreshing,
  shareDisabled,
  syncState,
  source,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslations();
  const { data: flags } = useFeatureFlags();
  const [shouldShowManageSourceDialog, setShouldShowManageSourceDialog] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleAction = (action: () => void): void => {
    action();
    handleClose();
  };

  return (
    <div className={classes.iconButtonContainer}>
      <IconButton
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-label="more"
        classes={{ root: classes.moreOptionButton }}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuContainer }}
        id="simple-menu"
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem disabled={refreshDisabled || isRefreshing} onClick={onRefresh}>
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            {isRefreshing && syncState ? (
              <div className={classes.syncProgressContainer}>
                <SyncProgress
                  duration={syncState.duration}
                  progression={syncState.progression}
                  size={20}
                  status={syncState.status}
                />
              </div>
            ) : (
              <RefreshIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={t('refresh')} />
        </MenuItem>
        {flags?.withSharedConnectors.enabled ? (
          <MenuItem
            disabled={deleteDisabled || shareDisabled}
            onClick={() => handleAction(onShare)}
          >
            <ListItemIcon classes={{ root: classes.itemIcon }}>
              <GroupAddIcon />
            </ListItemIcon>
            <ListItemText primary={t('share')} />
          </MenuItem>
        ) : null}
        <MenuItem disabled={deleteDisabled} onClick={() => setShouldShowManageSourceDialog(true)}>
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={t('source_settings')} />
        </MenuItem>
        <MenuItem disabled={deleteDisabled} onClick={() => handleAction(onDelete)}>
          <ListItemIcon classes={{ root: classes.itemIcon }}>
            <DeleteIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={t('delete')} primaryTypographyProps={{ color: 'secondary' }} />
        </MenuItem>
      </Menu>
      {shouldShowManageSourceDialog ? (
        ![Source.LocalDisk, Source.NetworkDisk].includes(source) ? (
          <ConfigureSourceDialog
            connector={{ id: connectorId, name: connectorName, source }}
            isManagingSource
            setShouldShowDialog={setShouldShowManageSourceDialog}
            shouldShowDialog={shouldShowManageSourceDialog}
          />
        ) : (
          <ConfigureLocalConnectorDialog
            connectorId={connectorId}
            isManagingSource
            name={connectorName}
            setShouldShowDialog={setShouldShowManageSourceDialog}
            shouldShowDialog={shouldShowManageSourceDialog}
            source={source as LocalSources}
          />
        )
      ) : null}
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ManageSourceMenuProps {
  connectorId: string;
  connectorName: string;
  deleteDisabled?: boolean;
  isRefreshing: boolean;
  onDelete: () => void;
  onRefresh: () => void;
  onShare: () => void;
  refreshDisabled: boolean;
  shareDisabled: boolean;
  source: Source;
  syncState?: ConnectorSyncState;
}
