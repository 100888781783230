import { KeyHandler } from '@outmind/helpers';
import { SearchSuggestionsResult } from '@outmind/types';
import React, { memo, useEffect, useRef } from 'react';

import { Label, useTranslations } from '../../../hooks';
import { List, Paper } from '../../../material';
import { SearchSuggestion } from './SearchSuggestion';
import { useStyles } from './styles';
import { LabelsSuggestion } from './SuggestionTypes/LabelsSuggestion';

const SearchSuggestionsNP: React.FC<SearchSuggestionsProps> = ({
  barRef,
  cursor,
  decrementCursor,
  filteredLabels,
  hideSuggestions,
  incrementCursor,
  isActive,
  inSpotlight,
  isLoading,
  isSharp,
  onClickSuggestion,
  reset,
  setSearchBarQ,
  suggestions,
  suggestionsQ,
}) => {
  const classes = useStyles({ inSpotlight, isSharp });

  const { t } = useTranslations();

  const barContainerRef = useRef<HTMLDivElement>();

  const downIsPressed = KeyHandler.useKeyDown('ArrowDown');
  const upIsPressed = KeyHandler.useKeyDown('ArrowUp');

  useEffect(() => {
    const handleClickOut = (e: MouseEvent): void => {
      if (barContainerRef && barContainerRef.current) {
        if (
          barContainerRef.current?.contains(e.target as Node) ||
          barRef?.current?.contains(e.target as Node) ||
          (e.target as HTMLInputElement)?.name === 'q' // We don't want to hide suggestions when we click on the input (name === q)
        ) {
          return;
        }
        hideSuggestions();
      }
    };
    document.addEventListener('click', handleClickOut);

    return () => {
      document.removeEventListener('click', handleClickOut);
    };
  }, [barContainerRef, barRef, hideSuggestions]);

  useEffect(() => {
    if (isActive && (suggestions.length || filteredLabels.length)) {
      if (downIsPressed) {
        incrementCursor();
      } else if (upIsPressed) {
        decrementCursor();
      }
    }
  }, [downIsPressed, upIsPressed]);

  useEffect(() => {
    if (!filteredLabels[cursor]) {
      const suggestion = suggestions[cursor - filteredLabels.length];
      if (!suggestion) return;

      const {
        document: { action },
      } = suggestion;
      if (action.type === 'filter:byPerson') {
        setSearchBarQ(`by:${action.value}`);
      } else if (action.type === 'filter:inFolder') {
        setSearchBarQ(`in:${action.value}`);
      } else if (action.type === 'query') {
        setSearchBarQ(action.value);
      } else {
        setSearchBarQ(suggestionsQ);
      }
    }
  }, [cursor, setSearchBarQ, suggestions, suggestionsQ, filteredLabels]);

  const searchSuggestions = suggestions.map((suggestion, index) => {
    return (
      <SearchSuggestion
        key={suggestion.document.id}
        index={index + filteredLabels.length}
        inSpotlight={inSpotlight}
        isSelected={index + filteredLabels.length === cursor}
        onClick={() => onClickSuggestion(suggestion)}
        suggestion={suggestion}
      />
    );
  });

  if (isActive && (suggestions.length || filteredLabels.length)) {
    return (
      <Paper
        ref={barContainerRef}
        className={classes.suggestionsContainer}
        elevation={3}
        id="suggestions"
      >
        <List component="nav">
          <LabelsSuggestion cursor={cursor} labels={filteredLabels} reset={reset} />
          {searchSuggestions}
        </List>
      </Paper>
    );
  }

  if (isActive && !suggestions.length && !isLoading && !filteredLabels.length) {
    return (
      <Paper ref={barContainerRef} className={classes.suggestionsContainer} elevation={3}>
        <List component="nav">
          <i className={classes.noSuggestion}>{t('no_suggestions_helper')}</i>
        </List>
      </Paper>
    );
  }

  return null;
};

interface SearchSuggestionsProps {
  barRef?: React.RefObject<HTMLDivElement>;
  cursor: number;
  decrementCursor: () => void;
  filteredLabels: Label[];
  hideSuggestions: () => void;
  inSpotlight?: boolean;
  incrementCursor: () => void;
  isActive: boolean;
  isLoading?: boolean;
  isSharp?: boolean;
  onClickSuggestion: (suggestion: SearchSuggestionsResult) => void;
  reset: () => void;
  setSearchBarQ: (q: string) => void;
  suggestions: SearchSuggestionsResult[];
  suggestionsQ: string;
}

export const SearchSuggestions = memo(SearchSuggestionsNP);
