import { Filters as FiltersEnum } from '@outmind/types';
import React from 'react';

import { useFeatureFlags } from '../../hooks';
import { useSelector } from '../../store';
import { Labels as LabelsFilter } from '../Labels';
import { CategoryFilters } from './CategoryFilters';
import { ConnectorFilters } from './ConnectorFilters';
import { DateFilter } from './DateFilter';
import { FieldFilters } from './FieldFilters';
import { SortBySelect } from './SortBySelect';
import { useStyles } from './styles';

export const Filters: React.FC = () => {
  const classes = useStyles();

  const filters = useSelector((s) => s.filters.filters);
  const { data: flags } = useFeatureFlags();

  const filtersComponents: Record<FiltersEnum, JSX.Element | undefined> = {
    [FiltersEnum.byOwners]: <ConnectorFilters key={FiltersEnum.byOwners} />,
    [FiltersEnum.byCategories]: <CategoryFilters key={FiltersEnum.byCategories} />,
    [FiltersEnum.byFields]: <FieldFilters key={FiltersEnum.byFields} />,
    [FiltersEnum.sortBy]: <SortBySelect key={FiltersEnum.sortBy} />,
    [FiltersEnum.byDateTo]: <DateFilter key={FiltersEnum.byDateTo} />,
    [FiltersEnum.byLabels]: flags?.withDocumentLabels.enabled ? (
      <LabelsFilter key={FiltersEnum.byLabels} />
    ) : undefined,
    [FiltersEnum.byConnectorIds]: undefined,
    [FiltersEnum.byClusterId]: undefined,
    [FiltersEnum.byFolder]: undefined,
    [FiltersEnum.byPerson]: undefined,
    [FiltersEnum.byDateFrom]: undefined,
    [FiltersEnum.byRelatedDocuments]: undefined,
    [FiltersEnum.byPermissions]: undefined,
  };

  const displayAvailableFilters = (): JSX.Element[] =>
    Object.keys(filtersComponents).reduce((availableFilters, filterKey) => {
      if (filters.includes(filterKey as FiltersEnum) && filtersComponents[filterKey]) {
        availableFilters.push(filtersComponents[filterKey]);
      }
      return availableFilters;
    }, [] as JSX.Element[]);

  return <div className={classes.filtersContainer}>{displayAvailableFilters()}</div>;
};
